import { t } from '@lingui/macro';
import { AboutModal, TextContent } from '@patternfly/react-core';
import React, { useEffect, useState } from 'react';
import Logo from 'src/../static/images/logo_large.svg';
import { ApplicationInfoAPI, UserType } from 'src/api';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  user: UserType;
  userName: string;
}

interface IApplicationInfo {
  astra_version?: string;
  aap_version?: string;
  galaxy_importer_version?: string;
  galaxy_ng_commit?: string;
  galaxy_ng_version?: string;
  pulp_ansible_version?: string;
  pulp_container_version?: string;
  pulp_core_version?: string;
  server_version?: string;
}

export const AboutModalWindow = ({ isOpen, onClose }: IProps) => {
  const [applicationInfo, setApplicationInfo] = useState<IApplicationInfo>({});

  useEffect(() => {
    ApplicationInfoAPI.get().then(({ data }) => setApplicationInfo(data));
  }, []);

  const { astra_version } = applicationInfo;

  const createSpeechBubble = () => {
    const version = astra_version ? astra_version : '1.0.0';
    let text = `${APPLICATION_NAME} ${version}`;

    let top = '_'.repeat(text.length);
    let bottom = '-'.repeat(text.length);

    top = ` __${top}__ \n`;
    text = `<  ${text}  >\n`;
    bottom = ` --${bottom}-- `;

    return top + text + bottom;
  };

  const speechBubble = createSpeechBubble();

  const copyright = t`Copyright`;
  const astraLLC = t`LLC «RusBITech-ASTRA»`;

  return (
    <AboutModal
      brandImageAlt={t`Galaxy Logo`}
      brandImageSrc={Logo}
      isOpen={isOpen}
      onClose={onClose}
      productName={APPLICATION_NAME}
      trademark={`${copyright} ${new Date().getFullYear()} ${astraLLC}`}
    >
      <TextContent>
        <pre style={{ fontSize: 11, background: 0, border: 0 }}>
          {speechBubble}
          {`
          \\
           \\           ___
            \\       .-'   \`'.
             \\     /         \\
              \\    |         ;
                   |         |           ___.--,
          _.._     |0) ~ (0) |    _.---'\`__.-( (_.
   __.--'\`_.. '.__.\\    '--. \\_.-' ,.--'\`     \`""\`
  ( ,.--'\`   ',__ /./;   ;, '.__.'\`    __
  _\`) )  .---.__.' / |   |\\   \\__..--""  """--.,_
 \`---' .'.''-._.-'\`_./  /\\ '.  \\ _.-~~~\`\`\`\`~~~-._\`-.__.'
       | |  .' _.-' |  |  \\  \\  '.               \`~---\`
        \\ \\/ .'     \\  \\   '. '-._)
         \\/ /        \\  \\    \`=.__\`~-.
         / /\\         \`) )    / / \`"".\`\\
   , _.-'.'\\ \\        / /    ( (     / /
    \`--~\`   ) )    .-'.'      '.'.  | (
           (/\`    ( (\`          ) )  '-;
            \`      '-;         (-'
                    `}
        </pre>
      </TextContent>
    </AboutModal>
  );
};
